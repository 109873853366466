import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import WhoAreWe from './WhoAreWe';
import styles from './styles.module.sass';

export default () => (
  <SectionWrapper id={i18n('_hash:vision')} className={styles.container}
    direction='row-reverse'>
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} container justifyContent='center'
      alignContent='center'
      alignItems='center'>
      <Typography
        variant='h2'
        weight='regular'
        align='left'
        className={styles.leftContainer}>
        {i18n('For a safer, better, smarter future powered by data.')}
      </Typography>
    </Grid>
    <WhoAreWe/>
  </SectionWrapper>
)