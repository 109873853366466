import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import WhoAreWe from './WhoAreWe';
import styles from './styles.module.sass';

export default () => (
  <SectionWrapper className={styles.container}>
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} container justifyContent='center' alignContent='center' alignItems='center'>
      <Typography
        variant='h1'
        weight='regular'
        align='left'
        className={styles.leftContainer}>
        {i18n('The platform enabling the fastest delivery of connected car services.')}
      </Typography>
    </Grid>
    <WhoAreWe/>
  </SectionWrapper>
)