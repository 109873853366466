import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "Be-Connected.jpg"}}) {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <SectionWrapper>
        <Grid container justifyContent='center' alignContent='center' alignItems='center'
          className={styles.container}>
          <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}
            alignContent='center'
            alignItems='center'
            justifyContent='flex-start'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('The digital world powered by OBI+')}
            </Typography>
            <Typography variant='subtitle1'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Next generation of connected car services in one place. Welcome to a world of digital touchpoints that create value, deliver insights and enable growth.')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={styles.imageWrapper}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              className={styles.imageContainer}
              fluid={pic.fluid}/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);