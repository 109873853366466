import React from 'react';
import Img from "gatsby-image/withIEPolyfill";
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({ img, text }) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <Typography variant='h5' color='dark'
        align='left'
        className={styles.textContainer}>
        {text}
      </Typography>
      <Img imgStyle={{ objectFit: 'contain' }}
        objectFit='contain'
        className={styles.imageContainer}
        fluid={img.fluid}
        alt={text}/>
    </div>
  </div>
)